body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans','Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Couries New', monospace; }

#root {
  height: 100vh; }

.flex {
  display: flex; }
  .flex-1 {
    flex: 1; }
  .flex-6 {
    flex: 6; }
  .flex-jc-sb {
    justify-content: space-between; }
  .flex-jc-sa {
    justify-content: space-around; }
  .flex-jc-c {
    justify-content: center; }
  .flex-jc-s {
    justify-content: stretch; }
  .flex-jc-start {
    justify-content: start; }
  .flex-jc-end {
    justify-content: end; }
  .flex-ai-c {
    align-items: center; }
  .flex-ai-s {
    align-items: stretch; }
  .flex-dir-c {
    flex-direction: column; }
  .flex-dir-r {
    flex-direction: row; }

.dark-glass-container {
  backdrop-filter: blur(5px) saturate(180%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  background-color: rgba(181, 201, 241, 0.5);
  border-radius: 5px;
  border: 1px solid rgba(36, 92, 217, 0.125);
  display: flex;
  justify-content: center;
  align-items: center; }

.ant-card {
  height: 100%;
  max-height: 100%;
  border: 0px !important;
  text-align: center !important;
  background-color: transparent; }

.ant-layout-content {
  max-width: calc(100% - 60px);
  min-width: calc(100% - 200px); }

.ant-layout {
  background-color: transparent;
  height: 100%; }

.ant-btn-small {
  font-size: 10px !important;
  height: 18px !important; }

.ant-card-head {
  border: none; }

.ant-card-body {
  height: calc(100% - 80px);
  padding-top: 0; }

.ant-table {
  backdrop-filter: blur(5px) saturate(180%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  background-color: rgba(181, 201, 241, 0.5);
  border-radius: 5px;
  border: 1px solid rgba(36, 92, 217, 0.125); }
  .ant-tabletable {
    border-radius: 5px; }

.ant-table-container {
  position: static !important;
  border-radius: 5px; }
  .ant-table-container:before {
    border-radius: 5px; }
  .ant-table-container:after {
    border-radius: 5px; }
  .ant-table-container::-webkit-scrollbar {
    background-color: transparent;
    width: 7px; }
  .ant-table-container::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #9fb3eb; }
    .ant-table-container::-webkit-scrollbar-thumb:hover {
      background-color: #799be8; }
  .ant-table-container::-webkit-scrollbar-track {
    background-color: transparent; }
  .ant-table-container {
    scrollbar-face-color: #9fb3eb;
    scrollbar-track-color: transparent; }

.ant-table-content::-webkit-scrollbar {
  background-color: transparent;
  height: 7px; }

.ant-table-content::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #9fb3eb; }
  .ant-table-content::-webkit-scrollbar-thumb:hover {
    background-color: #799be8; }

.ant-table-content::-webkit-scrollbar-track {
  background-color: transparent; }

.ant-table-content {
  scrollbar-face-color: #9fb3eb;
  scrollbar-track-color: transparent; }

.ant-table-thead > tr > th {
  background-color: transparent; }

.ant-talbe-thead > tr {
  backdrop-filter: blur(5px) saturate(180%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  background-color: rgba(181, 201, 241, 0.8);
  border: 1px solid rgba(36, 92, 217, 0.125); }

.ant-table-tbody > tr:hover > td {
  background-color: #b5c9f1 !important; }

.ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 5px; }

.ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 5px; }

.ant-table-tbody > tr:nth-child(odd) {
  background-color: #9fb3eb !important; }

.ant-table-container {
  overflow-y: auto;
  max-height: calc(100vh - 163px); }

.ant-layout-sider-light {
  background-color: transparent; }

.ant-menu {
  background-color: transparent;
  backdrop-filter: blur(5px) saturate(180%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  background-color: rgba(181, 201, 241, 0.5);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid rgba(36, 92, 217, 0.125); }

.mb-10 {
  margin-bottom: 10px; }

.mb-11 {
  margin-bottom: 11px; }

.mb-12 {
  margin-bottom: 12px; }

.mb-13 {
  margin-bottom: 13px; }

.mb-14 {
  margin-bottom: 14px; }

.mt-10 {
  margin-top: 10px; }

.mt-11 {
  margin-top: 11px; }

.mt-12 {
  margin-top: 12px; }

.ml-10 {
  margin-left: 10px; }

.ml-11 {
  margin-left: 11px; }

.ml-12 {
  margin-left: 12px; }

.mr-7 {
  margin-right: 5px; }

.mr-10 {
  margin-right: 10px; }

.mr-11 {
  margin-right: 11px; }

.mr-12 {
  margin-right: 12px; }

.ant-input-search-button {
  border: 1px solid rgba(36, 92, 217, 0.125);
  backdrop-filter: blur(5px) saturate(180%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  background-color: rgba(181, 201, 241, 0.5); }
  .ant-input-search-button:hover {
    background-color: #b5c9f1 !important; }

.ant-input-affix-wrapper {
  border: 1px solid rgba(36, 92, 217, 0.125);
  backdrop-filter: blur(5px) saturate(180%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  background-color: rgba(181, 201, 241, 0.5); }

.searchBar .ant-input {
  background-color: transparent; }
  .searchBar .ant-input::placeholder {
    color: #9fb3eb; }

.ant-btn-primary {
  background-color: #245cd9;
  border: 1px solid #245cd9;
  border-radius: 5px; }
  .ant-btn-primary:hover {
    background-color: #799be8;
    border: 1px solid #799be8; }

.data-card {
  max-height: 100%; }

.chart-container {
  padding: 10px;
  backdrop-filter: blur(5px) saturate(180%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  background-color: rgba(181, 201, 241, 0.5);
  border-radius: 5px;
  border: 1px solid rgba(36, 92, 217, 0.125); }

.accounts .features-box {
  margin-bottom: 15px; }

.accounts .export {
  text-align: right; }

.migration__content {
  text-align: center;
  height: 100%; }

.migration__body {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.migration-status .upload {
  margin-bottom: 10px;
  width: 100%;
  text-align: right; }

.partner-tokens .disableRow {
  background-color: lightgray; }

.partner-tokens .disableRow:hover > td {
  background-color: lightgray !important; }

.partner-tokens .partner-table {
  margin-top: 10px; }

#home {
  height: 100vh; }

#login-section-container {
  height: 100px;
  width: 300px; }

#login-button {
  background-color: #f6f9fa;
  color: #245cd9;
  border: 1px solid rgba(36, 92, 217, 0.125);
  transition: 0.5s; }
  #login-button:hover {
    background-color: #245cd9;
    color: #f6f9fa; }

.users .ant-input-search {
  margin-bottom: 15px; }

.phoneNumbers {
  content: \u2B24; }
  .phoneNumbers .features-box {
    margin-bottom: 15px; }
  .phoneNumbers .export {
    text-align: right; }
  .phoneNumbers .dot {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    display: inline-block; }
    .phoneNumbers .dot-green {
      background-color: #53ff00; }
    .phoneNumbers .dot-red {
      background-color: #ff0000; }
    .phoneNumbers .dot-yellow {
      background-color: #fffa00; }
    .phoneNumbers .dot-black {
      background-color: #000000; }

.login__link {
  color: #007bff;
  cursor: pointer;
  fontWeight: 500; }

.text-left {
  text-align: left; }

.active {
  color: #1890ff !important; }

.in-active {
  color: gray !important; }

.mr-1em {
  margin-right: 1em; }

.card-item {
  display: flex;
  width: 100%;
  text-align: left;
  margin-bottom: 0.75em;
  border-bottom: 2px solid gray; }
  .card-item__title {
    background-color: #f8f8f8;
    font-weight: bold;
    min-width: 150px;
    padding: 1em;
    margin-right: 1em;
    display: flex;
    align-items: center; }
  .card-item__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em; }

.custom-input {
  display: flex;
  cursor: pointer; }
  .custom-input__controller {
    padding: 0.5em 0.75em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-right: 1px solid gray; }
  .custom-input__label {
    font-weight: bold;
    padding: 0.5em 0.75em;
    width: 120px;
    text-align: left; }
  .custom-input__editor {
    flex-grow: 1; }

.dynamic-form-item {
  display: flex;
  border-bottom: 2px solid gray;
  margin-bottom: 1.5em; }
  .dynamic-form-item__label {
    font-weight: bold;
    padding: 0.5em 0.75em;
    width: 185px;
    text-align: left; }
  .dynamic-form-item__list {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
    .dynamic-form-item__list__item {
      width: 100% !important;
      display: flex;
      align-items: center; }
